<template>
  <div class="box">
    <div class="header">
      <p class="title">
        <span>兴农管家V1.0</span>
      </p>
      <p class="SecondaryTitle">
        <span> 为农助力 贴心服务 </span>
      </p>
    </div>
    <div class="login-from">
      <div class="loginFrom-top">
        <span id="login">登录</span>
        <span @click="toRegister">注册</span>
      </div>
      <div class="loginFrom-bottom">
        <p class="inputTop">
          <input
            v-model="username"
            name="name"
            left-icon="manager"
            type="text"
            placeholder="请输入您的手机号/邮箱"
            :rules="[{ required: true, message: '请填写用户名' }]"
          />
          <img src="../../assets/image/login/账户.png" alt="" />
        </p>
        <p class="inputBottom">
          <input
            type="password"
            v-model="password"
            name="pwd"
            placeholder="请输入您的登录密码"
            :rules="[{ required: true, message: '请填写密码' }]"
          />
          <img src="../../assets/image/login/密码.png" alt="" />
          <!-- <img src="eyeCheck ? 'closeEye' : 'eye'" alt=""> -->
        </p>
        <div class="yes">
          <span>
            <input class="checkbox" type="checkbox" v-model="checked" />
            同意<a href="#">《用户协议》</a>和<a href="#">《隐私政策》</a>
            </span>
        </div>
        <button class="button" @click="onSubmit">立即登录</button>
        <p class="password">
          <span @click="toReset">忘记密码?</span>
        </p>
      </div>
    </div>
    <!-- <div class="bottom">
      <p>更多登陆方式</p>
      <div class="image">
        <div class="image-img">
          <img src="../../assets/image/login/微信.png" alt="" />
        </div>
        <div class="image-img">
          <img src="../../assets/image/login/QQ.png" alt="" />
        </div>
        <div class="image-img">
          <img src="../../assets/image/login/微博.png" alt="" />
        </div>
      </div>
    </div> -->
  </div>
</template>
<script>
import { login } from "@/service/loginService"
import { Toast } from "vant"
// import closeEye from "../../assets/image/login/biyanjing.png"
// import eye from "../../assets/image/login/yanjing.png"
export default {
  name: "Login",
  data() {
    return {
      themeColor: "#5352ed",
      username: "",
      password: "",
      checked: true,
      eyeCheck: true
    }
  },
  components: {},
  methods: {
    onSubmit(values) {
      if (!this.checked) {
        Toast("请勾选同意《用户政策》和《隐私协议》")
        return
      }
      login(this.username, this.password).then(
        (res) => {
          if (res.code === 200) {
            this.$store.commit(
              "setToken",
              `${res.data.tokenHead}${res.data.token}`
            )

            this.$store.commit("setUser", res.data.sysUser)
            this.$store.commit("setTel", res.data.sysUser.mobile)
            this.$router.replace("/")
          } else if (res.code === 500) {
            Toast.fail(res.message)
          }
        },
        (err) => {
          console.log(err)
        }
      )
    },
    toRegister() {
      this.$router.push("/register")
    },
    toReset() {
      this.$router.push("/resetpwd")
    },
    toggleEye() {
      this.eyeCheck = !this.eyeCheck
    }
  }
}
</script>
<style lang='scss' scoped>
// $color: #5352ed;
// .wrapper {
//   .top {
//     background: $color;
//     padding: 24px 0;
//     color: #fff;
//     text-align: center;
//   }
//   .forget-pwd {
//     margin-left: 32px;
//   }
// }
html,
body {
  min-height: 100vh;
}

* {
  padding: 0;
  margin: 0;
}

.box {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 33.3vh;
  background-image: url(../../assets/image/login/header.png);
}

.title {
  margin-top: 3.1vh;
}

.title span {
  width: 12.1875rem;
  font-size: 2.4vh;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #ffffff;
  line-height: 10.8vh;
}

.SecondaryTitle span {
  font-size: 3.6vh;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #ffffff;
  line-height: 5.4vh;
}

.login-from {
  flex: 1;
  margin-top: -15.3vh;
  width: 97%;
  padding: 1.5vw;
}

.loginFrom-top {
  background-color: #fff;
  margin-top: 3vh;
  height: 11.4vh;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom: 1px solid #fff;
  #login{
    font-size: 2.7vh;
    padding: 5px;
    border-bottom: 1px solid #5064EB;
  }
}

.loginFrom-top span {
  display: inline-block;
  font-size: 2.1vh;
  color: #1e1e1e;
  margin-top: 3.4vh;
  margin-left: 4.6vh;
}

.loginFrom-bottom {
  padding: 2.3vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 48vh;
  width: 93%;
  position: relative;
}

.loginFrom-bottom p {
  width: 100%;
}

.loginFrom-bottom p input {
  box-sizing: border-box;
  width: 100%;
  background: #e9f0ff 100%;
  height: 6.6vh;
  border-radius: 3vh;
  padding: 0 6.9vh;
  border: none;
}

.inputTop img {
  width: 2.4vh;
  height: 3vh;
  position: absolute;
  left: 4vh;
  top: 2.8vh;
}

.inputBottom {
  margin-top: 4vh;
}

.inputBottom img {
  width: 2.4vh;
  height: 3vh;
  position: absolute;
  left: 4vh;
  top: 13.4vh;
}

.button {
  width: 100%;
  height: 6.6vh;
  margin-top: 7.3vh;
  border-radius: 3vh;
  background: linear-gradient(0deg, #2a6eff, #2c7cf9);
  color: #ffffff;
  border: none;
}

.password {
  border: none;
  width: 100%;
  margin-top: 2.3vh;
  text-align: center;
}

.password span {
  color: #2a6ffe;
  font-size: 1.8vh;
  font-family: Source Han Sans CN;
  font-weight: 400;
  text-decoration: underline;
}

.bottom {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bottom p {
  font-size: 1.8vh;
  color: #262626 100%;
  font-family: SourceHanSansCN-Regular;
}

.image {
  width: 100%;
  display: flex;
  margin-top: 5.5vh;
}

.image .image-img {
  flex: 1;
  text-align: center;
}

.image-img img {
  height: 21px;
}
.eye {
  position: absolute;
  right: 4vw !important;
}
.yes {
  margin-top: 3vh;
  text-align: left;
  span{
    width:93vw;
    display: block;
    text-align: left !important;
  }
}
.checkbox {
  margin-right: 2vw;
  width: 4vw;
  height: 2vh;
}
</style>
